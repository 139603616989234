import React from 'react';
import Page from '../components/Page'
import ReviewComponent from '../components/ReviewComponent'



const SubmitReviewPage = () => {
  return (
    <Page>
      <ReviewComponent/>
    </Page>
  );
}

export default SubmitReviewPage;
